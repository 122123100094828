.topnavbar-container {
  position: fixed;
  width: 100%;
  z-index: 9;
}
.topbar-container {
  width: 100%;
  height: 56px;
  background-color: #dddcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.brand-container.hide {
  animation-name: swipeUp;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}
.brand-container.show {
  animation-name: swipeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.brands-img {
  margin: 2rem;
  width: 70px;
  height: 35px;
}
.tag-line {
  position: absolute;
}
.tag-line.show {
  animation-name: swipeInText;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.tag-line.hide {
  animation-name: swipeUpText;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.navbar-container {
  width: 100%;
  height: 100.4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-subContainer {
  margin-left: 5rem;
}
.logo-tagline {
  display: flex;
  justify-content: end;
  color: #c1c0c0;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 3px;
  font-size: 13px;
  line-height: 0;
}
.logo {
  color: #242020;
  font-size: 28pt;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}
.logo-link {
  text-decoration: none;
  color: black;
}
.upper-container {
  display: flex;
  margin-bottom: 1rem;
}
.link {
  color: black;
  display: flex;
  align-items: center;
}
.upper-container-items {
  margin-right: 5rem;
  font-size: 14pt;
}
.ico-link {
  margin-right: 1rem;
}
.ico {
  width: 25px;
  height: 25px;
}

.lower-container {
  display: flex;
  padding-left: 2rem;
}
.lower-container-items {
  margin-right: 3rem;
  text-decoration: none;
  font-size: 14pt;
  transition: 0.5s;
}
.lower-container-list {
  margin-right: 3rem;
  text-decoration: none;
  font-size: 14pt;
  transition: 0.5s;
}
.lower-container-list span:hover {
  color: #d2d1d1;
  cursor: pointer;
}
.lower-container-items:hover {
  color: #d2d1d1;
}
.lower-container-items:focus {
  color: #d2d1d1;
}

@keyframes swipeUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes swipeUpText {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(-200%);
  }
}

@keyframes swipeIn {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes swipeInText {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media screen and (max-width: 1140px) {
  .topnavbar-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .navbar-container {
    flex-direction: row;
    height: 100%;
    padding-top: 1rem;
  }
  .brands-img {
    width: 68px;
    height: 25px;
    margin: 1rem;
  }
  .logo-tagline {
    letter-spacing: 2px;
    font-size: 12 px;
  }
  .logo {
    font-size: 22pt;
  }
  .upper-container {
    flex-direction: column;
  }
  .upper-container-items {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 690px) {
  .topnavbar-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .navbar-container {
    flex-direction: column;
    height: 100%;
    padding-top: 1rem;
  }
  .brands-img {
    width: 68px;
    height: 25px;
    margin: 1rem;
  }
  .logo-tagline {
    letter-spacing: 2px;
    font-size: 10px;
  }
  .logo {
    font-size: 18pt;
  }
  .upper-container-items {
    margin-right: 1rem;
  }
  .upper-container {
    flex-direction: column;
  }
}
