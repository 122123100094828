.carousel-container {
  height: 130vh;
  padding-top: 10vh;
}
.carousel-img {
  height: 100vh;
  object-fit: cover;
}
.slide-info {
  position: absolute;
  top: 60%;
  left: 13%;
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slide-info h3 {
  color: white;
  font-size: 20pt;
  line-height: 0px;
  padding-left: 0.3rem;
}
.slide-info span {
  color: white;
  padding-left: 5.4rem;
}

.slide-button {
  position: absolute;
  width: 100px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  opacity: 1;
  top: 110px;
  left: 86px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .carousel-container {
    height: 110vh;
    padding-top: 1rem;
  }
  .slide-info h3 {
    color: white;
    line-height: 0px;
  }
  .slide-button {
    top: 90px;
    left: 8px;
  }
  .carousel-img {
    height: 70vh;
  }
}

@media only screen and (max-width: 690px) {
  .carousel-container {
    height: 100%;
    padding-top: 0.5rem;
  }
  .carousel-img {
    height: 50vh;
    object-fit: cover;
  }
  .slide-info {
    position: absolute;
    top: 42%;
    left: 5%;
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .slide-info h3 {
    color: white;
    font-size: 15pt;
    line-height: 0px;
    padding-left: 0.4rem;
  }
  .slide-button {
    top: 80px;
    height: 35px;
  }
}
