.container {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}
.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.aboutImg {
  object-fit: contain;
  width: 540px;
  height: 630px;
  margin-right: 1.5rem;
}
.info-details-container {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .container {
    height: 100%;
  }
  .info-details-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }
  .aboutImg {
    object-fit: contain;
    height: 250px;
    margin-left: 1.2rem;
  }
  .details {
    width: 90%;
  }
}

@media only screen and (max-width: 1140px) {
  .info-details-container {
    flex-direction: column;
  }
}
