.testimonials-contianer {
  height: 100%;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
}
.testimonials-reviews {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.testimonials-headings h1 {
  text-align: center;
  line-height: 0;
  margin-top: 3rem;
}
.testimonials-headings span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonials-headings img {
  object-fit: contain;
  width: 100px;
  margin-left: 0.2rem;
  margin-right: 1.5rem;
}
.testimonials-headings text {
  font-size: 18px;
}
.article-container {
  width: 11rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.3rem;
}
.article-container img {
  width: 147px;
  border-radius: 73.5px;
}
.article-container p {
  text-align: center;
  font-size: 15px;
}
.testimonials-more {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: end;
  height: 100%;
  margin-bottom: 1rem;
}
.testimonials-more span {
  font-size: 18px;
  margin-right: 0.2rem;
}
.testimonials-more a {
  color: black;
  font-size: 18px;
}

@media only screen and (max-width: 690px) {
  .testimonials-contianer {
    height: 100%;
  }
  .testimonials-reviews {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
