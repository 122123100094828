.contact-container {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.contact-container h2 {
  font-size: 100px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
  line-height: 0;
  margin-bottom: 4rem;
}
.contact-info-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-info-header span {
  line-height: 0;
  font-size: 18px;
  letter-spacing: 0.9px;
  color: #000000;
}
.contact-form-container {
  display: flex;
}
.form-links-container {
  margin-right: 5rem;
}
.form-links {
  display: flex;
  align-items: center;
}
.form-links p {
  margin-right: 1rem;
  font-weight: bold;
  font-size: 16px;
}
.form-links a {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
}
.form-fields input {
  width: 449px;
  height: 30px;
  margin-bottom: 0.5rem;
  font-family: brandon-grot;
  padding: 0.2rem;
  font-size: 16px;
  font-weight: bold;
}
.form-fields input::placeholder {
  color: #000000;
  font-weight: bold;
}
.form-fields textarea {
  height: 130px;
  font-family: brandon-grot;
  padding: 0.2rem;
  font-size: 16px;
  font-weight: bold;
}
.form-fields textarea::placeholder {
  color: #000000;
  font-weight: bold;
}
.form-fields button {
  margin-top: 0.5rem;
  width: 2.8rem;
  height: 2rem;
  background-color: #000000;
  color: white;
  align-self: flex-end;
}
.response-container {
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 100%;
}
.response-container p {
  color: green;
  font-weight: bold;
}
.info-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 4rem;
}
.map-container {
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .contact-container {
    height: 100%;
    align-items: center;
    display: block;
  }
  .contact-container h2 {
    font-size: 47px;
    font-weight: 400;
  }
  .contact-form-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .info-header-wrapper {
    margin-bottom: 3rem;
  }
  .form-links-container {
    margin-left: 70px;
    margin-bottom: 0.5rem;
  }
  .info-header-wrapper span {
    font-size: 16px;
  }
  .form-fields input {
    width: 320px;
    height: 30px;
    margin-bottom: 0.5rem;
    font-family: brandon-grot;
    padding: 0.2rem;
    font-size: 16px;
    font-weight: bold;
  }
  .form-fields textarea {
    height: 100px;
    font-family: brandon-grot;
    padding: 0.2rem;
    font-size: 16px;
    font-weight: bold;
  }
  .form-fields button {
    margin-bottom: 1rem;
  }
  .map-container {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .map-container {
    width: auto;
  }
}
